<template>
  <div>
    <el-row>
      <el-col :span="4" class="flexs">
        <span class="width">市场</span>
        <el-select v-model="params.market_id" placeholder="请选择">
          <el-option
            v-for="item in data.marketid"
            :key="item.id"
            :label="item.comp_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4" class="flexs">
        <span class="width">签收负责人</span>
        <el-select
          v-model="params.market_manage_personnel_id"
          placeholder="请选择"
        >
          <el-option
            v-for="item in data.ManageList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4" class="flexs">
        <span class="width">设备状态</span>
        <el-select v-model="params.device_status" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4" class="flexs">
        <span class="width">经营户</span>
        <el-select v-model="params.seller_id" placeholder="请选择">
          <el-option
            v-for="item in data.SellerByMarket"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4" class="flexs">
        <span class="width">设备编码</span>
        <el-input
          v-model="params.device_id"
          placeholder="请输入设备编码"
          style="width:217px;"
        ></el-input>
      </el-col>
      <el-col :span="4" class="flexs" style="margin-top: 10px;">
        <span class="width">机器码</span>
        <el-input
          v-model="params.machine_code"
          placeholder="请输入机器码(SN)"
          style="width:217px;"
        ></el-input>
      </el-col>
      <el-col :span="4" class="flexs" style="margin-top: 10px;">
        <el-button type="primary" @click="get_device">查询</el-button>
        <el-button @click="isyunshow" type="primary">新增</el-button>
      </el-col>
    </el-row>
    <el-table :data="tableData" border style="width: 100%;margin-top:20px">
      <el-table-column prop="" label="设备类型">
        <template slot-scope="scope">
          <span v-if="scope.row.device_category * 1 === 1">电子秤</span>
          <span v-if="scope.row.device_category * 1 === 2">商户屏</span>
        </template>
      </el-table-column>
      <el-table-column prop="market_name" label="市场名称"> </el-table-column>
      <el-table-column prop="market_manage_personnel_id" label="签收负责人">
      </el-table-column>

      <el-table-column prop="live_time_minute" label="在线时长"> </el-table-column>
      <el-table-column prop="manu_name" label="设备厂商"> </el-table-column>
      <el-table-column prop="device_id" label="设备编号"> </el-table-column>
      <el-table-column prop="seller_name" label="经营户"> </el-table-column>
      <el-table-column prop="machine_code" label="机器码"> </el-table-column>
      <el-table-column prop="mac_address" label="mac地址"> </el-table-column>
      <el-table-column prop="device_status" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.device_status === 1">启用</span>
          <span v-if="scope.row.device_status === 2">禁用</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <!-- <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
            >设置快捷键
          </el-button>
          <el-button size="mini" @click="handleDelete(scope.$index, scope.row)"
            >设置打印小票</el-button
          > -->
          <!-- <el-button size="mini" @click="handleDelete(scope.$index, scope.row)"
            >换绑</el-button
          > -->
          <!--          <el-button size="mini" @click="isshow = true">维护</el-button>-->
          <el-button size="mini" @click="detail(scope.row)">设备详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div
      style="display: flex;justify-content: center;align-items: center;margin-top: 15px"
    >
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.page"
        :page-sizes="[10, 20, 30]"
        :page-size="params.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="params.total"
      >
      </el-pagination>
    </div>

    <!-- 电子秤新增 -->
    <addMaintain ref="addMaint" @my-event="saveitForm"></addMaintain>
    <detailsMaintain ref="detailMaint"></detailsMaintain>
    <!-- <维护> -->
    <el-dialog title="维护" :visible.sync="isshow" width="40%" center>
      <el-form ref="form" :model="formdata" label-width="120px">
        <el-form-item label="维护类型">
          <!-- <el-input v-model="formdata.name" style="width:40%"></el-input> -->
          <el-select
            v-model="formdata.maintain_type"
            placeholder="请选择所在市场"
          >
            <el-option
              v-for="item in WeHutype"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="维护描述">
          <el-input
            type="textarea"
            style="width:40%"
            :autosize="{ minRows: 4, maxRows: 4 }"
            placeholder="请输入维护类型"
            v-model="formdata.maintain_describe"
            maxlength="50"
            show-word-limit
          >
          </el-input>
        </el-form-item>

        <el-form-item prop="device_positive_pic" label="上传维护照片">
          <el-upload
            class="avatar-uploader"
            action="https://jsonplaceholder.typicode.com/posts/"
            :show-file-list="false"
            :http-request="uploadSectionFile1"
            :before-upload="beforeAvatarUpload"
            accept=".png"
            limit="3"
          >
            <img
              v-if="formdata.maintain_pic"
              :src="formdata.maintain_pic"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getmarket } from '../../../api/layout'
import { getSellerByMarketId } from '../../../api/productList'
import addMaintain from '../electronicList/addMaintain'
import detailsMaintain from '../electronicList/detailsMaintain'
import {
  getdeviceList,
  GETdeviceManu,
  POSTdevice,
  marketManageList,
  GETsellerList
} from '../../../api/electronicList'
import { upload } from '../../../utile/upload'

export default {
  name: 'electronicList',
  components: {
    addMaintain,
    detailsMaintain
  },
  data () {
    return {
      isshow: false,
      options: [
        { id: 1, name: '禁用' },
        { id: 2, name: '启用' }
      ],
      value: '',
      value1: '',
      search: {
        page: '',
        limit: ''
      },
      tableData: [], // 表格数据
      // 第一行数据
      data: {
        marketid: '', // 市场
        SellerByMarket: '', // 所属商户
        ManageList: '', // 签收负责人
        devicemanu: '', // 生产厂家名称
        devicedecid: '' // 设备规格
      },
      params: {
        market_id: '',
        market_manage_personnel_id: '',
        device_id: '',
        device_status: '',
        machine_code: '',
        page: 1,
        limit: 10,
        total: 0
      },
      formdata: {
        maintain_pic: '',
        maintain_type: '',
        maintain_describe: ''
      },
      WeHutype: [
        {
          id: 1,
          name: '更换设备'
        },
        {
          id: 2,
          name: '维护'
        }
      ]
    }
  },
  methods: {
    // 设备详情
    detail (row) {
      console.log(this.$refs.detailMaint)
      this.$refs.detailMaint.outerVisible = true
      this.$refs.detailMaint.getMoutdevice(row.id)
    },
    // 市场
    get_market () {
      getmarket().then(res => {
        if (res.code === 200) {
          this.data.marketid = res.data
        }
      })
    },
    // 选择啦市场
    clickmarket () {
      getSellerByMarketId(this.ruleForm.market_id).then(res => {
        this.data.SellerByMarket = res.data
      })
    },
    // 设备厂商
    GET_deviceManu () {
      GETdeviceManu(1, 100).then(res => {
        this.data.devicemanu = res.data.data
      })
    },
    // 表格数据
    get_device () {
      getdeviceList(this.params).then(res => {
        this.tableData = res.data.data
        this.params.total = res.data.total
      })
    },
    // 上传图片的校验
    beforeAvatarUpload (file) {
      const isJPG1 = file.type === 'image/png'
      const isJPG2 = file.type === 'image/jpeg'
      const isJPG3 = file.type === 'image/bmp'
      const isJPG4 = file.type === 'image/gif'
      const img = [isJPG1, isJPG2, isJPG3, isJPG4]
      const some = img.some(item => {
        return item
      })
      if (!some) {
        this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
      }
      return some
    },
    // 获取真实的路径
    uploadSectionFile1 (param) {
      // this.formdata.maintain_pic = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleForm.device_positive_pic = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    uploadSectionFile2 (param) {
      // this.ruleForm.device_reverse_pic = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleForm.device_reverse_pic = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    uploadSectionFile3 (param) {
      // this.ruleForm.device_seal_pic = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleForm.device_seal_pic = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    uploadSectionFile4 (param) {
      // this.ruleForm.device_label_pic = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleForm.device_label_pic = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 签收负责人
    getManageList () {
      marketManageList().then(res => {
        console.log(res)
        if (res.code === 200) {
          this.data.ManageList = res.data.data
        }
      })
    },
    // 经营户
    get_sellerList () {
      GETsellerList().then(res => {
        if (res.code === 200) {
          this.data.SellerByMarket = res.data.data
          console.log(this.data.SellerByMarket)
        }
      })
    },
    isyunshow () {
      console.log(this.$refs.addMaint)
      this.$refs.addMaint.isyunshowdialog = true
      this.$refs.addMaint.marketid = this.data.marketid
      // this.$refs.addMaint.data.SellerByMarket = this.data.SellerByMarket
      this.$refs.addMaint.data.ManageList = this.data.ManageList
      this.$refs.addMaint.data.devicemanu = this.data.devicemanu
      // this.$refs.addMaint.data.ManageList = this.data.ManageList
      this.$refs.addMaint.data.SellerByMarket = this.data.SellerByMarket
      // this.$refs.addMaint.get_sellerList()
      // this.$refs.addMaint.getManageList()
    },
    // 表单提交
    saveitForm (num) {
      POSTdevice(num).then(res => {
        if (res.code === 200) {
          this.$message.success(`${res.data}`)
          this.get_device(this.search)
        } else {
          this.$message.error(`${res.msg}`)
        }
      })
    },
    handleSizeChange (val) {
      this.params.page = 1
      this.params.limit = val
      this.get_device()
    },
    handleCurrentChange (val) {
      this.params.page = val
      this.get_device()
    }
  },
  mounted () {
    this.get_market()
    this.get_device()
    this.GET_deviceManu()
    this.getManageList()
    this.get_sellerList()
    // this.get_deviceManu()
  }
}
</script>

<style lang="less" scoped>
.title1 {
  display: flex;
  width: 80%;
  justify-content: space-between;
}
.width {
  display: inline-block;
  width: 80px;
  text-align: center;
  // margin-right: 20px;
  flex-shrink: 0;
}
.upload {
  display: flex;
}
.visible {
  margin-top: 20px;
}
.uploadItem {
  text-align: center;
  margin: 0 10px;
}
.uploadItem1 {
  text-align: center;
  margin-right: 10px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.between {
  display: flex;
  justify-content: space-between;
}
.margin /deep/.el-form-item__content {
  margin-left: 0 !important;
}
.flexs {
  display: flex;
  // justify-content: space-around;
  align-items: center;
  margin-right: 20px;
}
</style>
