<template>
  <div>
    <el-dialog title="设备详情" :visible.sync="outerVisible">
      <el-card class="box-card" style="margin-bottom: 20px">
        <div slot="header" class="clearfix">
          <span>基本信息</span>
        </div>
        <el-row>
          <el-col :span="12">
            <div class="grid-content">所在市场:<span>{{ usersdetall.market_name	 }}</span> </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content">
              经营户:<span>{{ sellerShop.seller_name }}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content">经营户电话: <span>{{ usersdetall.tel }}</span></div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content">设备编号:<span>{{ usersdetall.id }}</span></div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content">设备名称:<span>{{ usersdetall.device_name }}</span></div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content">设备厂商:<span>{{ usersdetall.manu_name }}</span></div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content">创建时间:<span>{{ usersdetall.updated_at }}</span> </div>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="box-card" style="margin-bottom: 20px">
        <div slot="header" class="clearfix">
          <span>绑定信息记录</span>
        </div>
        <el-row>
          <el-col :span="12">
            <div class="grid-content">市场:<span>{{ sellerShop.market_name	 }}</span> </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content">商户: <span>{{ sellerShop.seller_name }}</span></div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content">绑定时间:<span>{{ sellerShop.created_at }}</span> </div>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="box-card" style="margin-bottom: 20px">
        <div slot="header" class="clearfix">
          <span>维护记录</span>
        </div>
        <el-table :data="usersdetall.device_maintain" style="width: 100%">
          <el-table-column prop="maintain_situation" label="维护情况" width="180">
          </el-table-column>
          <el-table-column prop="maintain_type" label="维护类型" width="180">
            <template slot-scope="scope">
              <span v-if="scope.row.maintain_type===1">电子秤</span>
              <span v-else>电子屏</span>
            </template>
          </el-table-column>
          <el-table-column prop="maintain_personnel" label="维护人"> </el-table-column>
          <el-table-column prop="created_at" label="维护时间"> </el-table-column>
        </el-table>
      </el-card>
      <el-card class="box-card" style="margin-bottom: 20px">
        <div slot="header" class="clearfix">
          <span>使用记录</span>
        </div>
        <el-table :data="usersdetall.device_heartbeat" style="width: 100%">
          <el-table-column  type="index"  label="序号"  width="120px">
          </el-table-column>
          <el-table-column prop="created_at" label="创建时间" >
          </el-table-column>
          <el-table-column prop="updated_at" label="结束时间" >
          </el-table-column>
          <el-table-column prop="live_time_minute" label="在线时间"> </el-table-column>
        </el-table>
      </el-card>
    </el-dialog>
  </div>
</template>
<script>
import {  getDevicedevice } from '../../../api/electronicList'
export default {
  data () {
    return {
      options: [],
      tableData: [],
      usersdetall:{},
      outerVisible:false,
      sellerShop:{}
    }
  },
  methods: {
    getMoutdevice (item) {
      console.log(item)
      getDevicedevice(item).then(res => {
        if (res.code === 200) {
          this.usersdetall = res.data
          this.sellerShop= res.data.sellerShop
          console.log(this.usersdetall)
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.grid-content {
  line-height: 40px;
  span{
    margin-left: 21px;
    color: #333;
    /* font-size: 14px; */
    font-weight: 600;
  }
}
.huibg {
  background: #e4e4e4;
  padding: 10px 15px;
  margin-bottom: 10px;
}
.btn-margin {
  margin: 7px 7px;
}
.active {
  display: none;
}
.none {
  display: block;
}
.tableclss /deep/ .el-table .cell {
  width: 100%;
}
</style>
