<template>
  <div>
    <el-dialog
      title="新增设备"
      :visible.sync="isyunshowdialog"
      width="40%"
      @close="closeClick"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="90px"
        class="demo-ruleForm"
      >
        <div class="between">
          <div>
            <el-form-item label="所在市场" prop="market_id">
              <el-select
                v-model="ruleForm.market_id"
                placeholder="请选择所在市场"
              >
                <el-option
                  v-for="item in marketid"
                  :key="item.id"
                  :label="item.comp_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属商户" prop="seller_id">
              <el-select
                v-model="ruleForm.seller_id"
                placeholder="请选择所属商户"
              >
                <el-option
                  v-for="item in data.SellerByMarket"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="签收负责人" prop="market_manage_personnel_id">
              <el-select
                v-model="ruleForm.market_manage_personnel_id"
                placeholder="请选择签收负责人"
              >
                <el-option
                  v-for="item in data.ManageList"
                  :label="item.name"
                  :key="item.id"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="机器码" prop="machine_code">
              <el-input
                placeholder="请输入机器码"
                v-model="ruleForm.machine_code"
                style="width:217px"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="mac地址" prop="mac_address">
              <el-input
                v-model="ruleForm.mac_address"
                placeholder="请输入mac地址"
                style="width:217px"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="设备类型" prop="device_category">
              <el-select
                v-model="ruleForm.device_category"
                placeholder="请选择"
              >
                <el-option label="电子秤" value="1"> </el-option>
                <el-option label="商户屏" value="2"> </el-option>
                <el-option label="触摸屏" value="3"> </el-option>
                <el-option label="可视化展示屏" value="4"> </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="设备名称" prop="device_name">
              <el-input
                v-model="ruleForm.device_name"
                placeholder="请输入设备名称"
                style="width:217px"
              >
              </el-input>
            </el-form-item>
            <!-- <el-form-item label="设备编号" prop="device_id">
              <el-select v-model="value1.value1" placeholder="请选择">
                <el-option label="选项一" value="1"> </el-option>
                <el-option label="选项一" value="1"> </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="设备型号" prop="device_type">
              <el-input
                placeholder="请输入设备型号"
                v-model="ruleForm.device_type"
                style="width:217px"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="设备规格" prop="device_dec_id">
              <!-- <el-select
                v-model="ruleForm.device_dec_id"
                placeholder="请选择设备规格"
              >
                <el-option
                  v-for="item in data.devicedecid"
                  :key="item.id"
                  :label="item.name + item.unit"
                  :value="item.id"
                >
                </el-option>
              </el-select> -->
              <el-input
                placeholder="请输入设备规格"
                v-model="ruleForm.device_dec"
                style="width:217px"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="设备厂商" prop="device_manu_id">
              <el-select
                v-model="ruleForm.device_manu_id"
                placeholder="请选择设备厂商"
              >
                <el-option
                  v-for="item in data.devicemanu"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备状态" prop="device_status">
              <el-select v-model="ruleForm.device_status">
                <el-option label="启用" value="2"></el-option>
                <el-option label="禁用" value="1"></el-option>
              </el-select>
            </el-form-item>
            <!--            <el-form-item label="设备模板" prop="device_demo">-->
            <!--              <el-select v-model="value1.value1" placeholder="请选择">-->
            <!--                <el-option label="选项一" value="1"> </el-option>-->
            <!--                <el-option label="选项一" value="1"> </el-option>-->
            <!--              </el-select>-->
            <!--            </el-form-item>-->
          </div>
        </div>
        <el-form-item label="备注信息" prop="comment">
          <el-input
            v-model="ruleForm.comment"
            placeholder="请输入备注信息"
          ></el-input>
        </el-form-item>
        <div class="between margin">
          <el-form-item prop="device_positive_pic">
            <div style="text-align:center">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :http-request="uploadSectionFile1"
                :before-upload="beforeAvatarUpload"
                accept=".png"
              >
                <img
                  v-if="ruleForm.device_positive_pic"
                  :src="ruleForm.device_positive_pic"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <b>设备正面照</b>
            </div>
          </el-form-item>
          <el-form-item prop="device_reverse_pic">
            <div style="text-align:center">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :http-request="uploadSectionFile2"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="ruleForm.device_reverse_pic"
                  :src="ruleForm.device_reverse_pic"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <b>设备反面照</b>
            </div>
          </el-form-item>
          <el-form-item prop="device_seal_pic">
            <div style="text-align:center">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :http-request="uploadSectionFile3"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="ruleForm.device_seal_pic"
                  :src="ruleForm.device_seal_pic"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <b>设备铅封照</b>
            </div>
          </el-form-item>
          <el-form-item prop="device_label_pic">
            <div style="text-align:center">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :http-request="uploadSectionFile4"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="ruleForm.device_label_pic"
                  :src="ruleForm.device_label_pic"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <b>设备标签照</b>
            </div>
          </el-form-item>
        </div>
        <div style="text-align:right">
          <el-button type="primary" @click="submitForm">确 认</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { upload } from '../../../utile/upload'
export default {
  data () {
    return {
      isyunshowdialog: false,
      value: '',
      value1: '',
      search: {
        page: '',
        limit: ''
      },
      tableData: [], // 表格数据
      // 第一行数据
      data: {
        marketid: '', // 市场
        SellerByMarket: '', // 所属商户
        ManageList: '', // 签收负责人
        devicemanu: '', // 生产厂家名称
        devicedecid: '' // 设备规格
      },

      ruleForm: {
        device_name: '', // 设备名称
        device_type: '', // 设备型号
        device_dec: '', // 设备规格
        machine_code: '', // 机器码
        mac_address: '', // mac地址
        device_manu_id: '', // 生产厂家名称
        seller_id: '', // 所属商户id
        market_id: '', //  所属市场id
        comment: '', // 备注信息

        device_positive_pic: '', // 设备正面照
        device_reverse_pic: '', // 设备反面照
        device_seal_pic: '', // 设备铅封照
        device_label_pic: '', // 设备标签照
        market_manage_personnel_id: '', // 签收负责人
        device_status: '', // 设备状态
        device_category: '', // 设备类型:1=电子秤,2=商户屏,3=触摸屏,4=可视化展示屏
        device_demo: '' // 设备模板 否
      },
      marketid: [],
      rules: {
        device_positive_pic: [
          {
            required: true,
            message: '请上传设备正面照',
            trigger: 'blur'
          }
        ],
        device_reverse_pic: [
          {
            required: true,
            message: '请上传设备反面照',
            trigger: 'blur'
          }
        ],
        device_seal_pic: [
          {
            required: true,
            message: '请上传设备铅封照',
            trigger: 'blur'
          }
        ],
        device_label_pic: [
          {
            required: true,
            message: '请上传设备标签照',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created () {},
  methods: {
    beforeAvatarUpload (file) {
      const isJPG1 = file.type === 'image/png'
      const isJPG2 = file.type === 'image/jpeg'
      const isJPG3 = file.type === 'image/bmp'
      const isJPG4 = file.type === 'image/gif'
      const img = [isJPG1, isJPG2, isJPG3, isJPG4]
      const some = img.some(item => {
        return item
      })
      if (!some) {
        this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
      }
      return some
    },
    // 获取真实的路径
    uploadSectionFile1 (param) {
      // this.ruleForm.device_positive_pic = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleForm.device_positive_pic = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    uploadSectionFile2 (param) {
      // this.ruleForm.device_reverse_pic = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleForm.device_reverse_pic = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    uploadSectionFile3 (param) {
      // this.ruleForm.device_seal_pic = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleForm.device_seal_pic = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    uploadSectionFile4 (param) {
      // this.ruleForm.device_label_pic = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleForm.device_label_pic = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 清空
    closeClick () {
      this.ruleForm = {
        device_name: '', // 设备名称
        device_type: '', // 设备型号
        device_dec_id: '', // 设备规格
        machine_code: '', // 机器码
        mac_address: '', // mac地址
        device_manu_id: '', // 生产厂家名称
        seller_id: '', // 所属商户id
        market_id: '', //  所属市场id
        comment: '', // 备注信息
        device_positive_pic: '', // 设备正面照
        device_reverse_pic: '', // 设备反面照
        device_seal_pic: '', // 设备铅封照
        device_label_pic: '', // 设备标签照
        market_manage_personnel_id: '', // 签收负责人
        device_status: '', // 设备状态
        device_category: '', // 设备类型:1=电子秤,2=商户屏,3=触摸屏,4=可视化展示屏
        device_demo: '' // 设备模板 否
      }
    },
    // 表单提交
    submitForm () {
      this.$emit('my-event', this.ruleForm)
      this.closeClick()
      this.isyunshowdialog = false
    }
  }
}
</script>
<style scoped>
.title1 {
  display: flex;
  width: 80%;
  justify-content: space-between;
}

.width {
  display: inline-block;
  width: 80px;
  text-align: left;
  margin-right: 20px;
  flex-shrink: 0;
}

.upload {
  display: flex;
}

.visible {
  margin-top: 20px;
}

.uploadItem {
  text-align: center;
  margin: 0 10px;
}

.uploadItem1 {
  text-align: center;
  margin-right: 10px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.between {
  display: flex;
  justify-content: space-between;
}

.margin /deep/.el-form-item__content {
  margin-left: 0 !important;
}
</style>
